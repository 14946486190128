import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { EmojiModule } from '@shared/emoji/emoji.module';
import { SharedPeopleModule } from '@shared/people/shared-people.module';
import { ScrollModule } from '@shared/scroll/scroll.module';
import { LinkyModule } from 'ngx-linky';
import { PeopleModule } from '../people/people.module';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatPageComponent } from './pages/chat-page/chat-page.component';
import { ChatStoreModule } from './store/chat-store.module';
import { ChatDetailComponent } from '@features/chat/smart-components/chat-detail/chat-detail.component';
import { ChatDetailFooterComponent } from '@features/chat/smart-components/chat-detail-footer/chat-detail-footer.component';
import { SanitiserModule } from '@shared/sanitiser/sanitiser.module';

@NgModule({
    declarations: [ChatPageComponent, ChatMessageComponent, ChatDetailComponent, ChatDetailFooterComponent],
    exports: [ChatDetailComponent],
    imports: [
        AppCommonModule,
        ChatRoutingModule,
        ChatStoreModule,
        PeopleModule,
        LinkyModule,
        SharedPeopleModule,
        ScrollModule,
        EmojiModule,
        SanitiserModule
    ]
})
export class ChatModule {}
